<template>
    <div>
        <public-frame-dark :menu="false">
        <div class="privacy">
            <div class=" page-container" v-html="statement"></div>
        </div>
        </public-frame-dark>
    </div>
</template>

<script>
import {mapState} from "vuex";
import PublicFrameDark from "@/components/public-frame-dark";
export default {
    name: "privacy",
    components: {PublicFrameDark},
    computed: {
        ...mapState(['preset']),
        statement() {
            return this.preset.app ? this.preset.app.privacy : '';
        }
    },
}
</script>

<style scoped lang="scss">
.privacy {
    background-color: #f8f8f8;
    padding: 20px;
    border-top: 1px solid #eee;
}
</style>
